<template>
    <div>
      <validation-observer ref="simple">
        <b-form>
          <b-card-code title="  تعديل  عقد شراء">
          <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
          <b-row>
            <b-col md="3" xl="3">
              <validation-provider #default="{ errors }" rules="required">
                <b-form-group
                  label-for="employe"
                  :state="errors.length > 0 ? false : null"
                >
             
                <label> امر الشراء</label>
                <v-select
                  v-model="purchase_order_id"
                  :options="optionPurchesOrder"
                  :reduce="(val) => val.value"
                />
              </b-form-group>
              <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-col>
           
            <b-col md="3" xl="3">
              <validation-provider #default="{ errors }" rules="required">
                <b-form-group
                  label-for="employe"
                  :state="errors.length > 0 ? false : null"
                >
             
                <label> المورد</label>
                <v-select
                  v-model="vendor_id"
                  label="name"
                  :options="optionVendor"
                  :reduce="(val) => val.id"
                />
              </b-form-group>
              <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-col>
         
            <b-col md="3" xl="3">
              <validation-provider #default="{ errors }" rules="">
                <b-form-group
                  label-for="notes"
                  :state="errors.length > 0 ? false : null"
                >
             
                <label> ملاحظة</label>
                <b-form-input v-model="notes" type="text" id="input-default" />
              </b-form-group>
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-col>
           
            <b-col md="1" xl="3">
              <b-button variant="purple" @click="update" >
                <feather-icon icon="PlusIcon" class="mr-50" />
                <span class="align-middle"> تعديل عقد الشراء</span>
              </b-button>
            </b-col>
          </b-row>
        </validation-observer>
        </b-card-code>
        </b-form>
      </validation-observer>
      <b-col md="4" xl="2">
      <b-button variant="purple " @click="$router.go(-1)">
        <span class="align-middle">رجوع</span>
      </b-button>
    </b-col>
    </div>
  </template>
  
  <script>
  import "vue-good-table/dist/vue-good-table.css";
  import { VueGoodTable } from "vue-good-table";
  import vSelect from "vue-select";
  import flatPickr from "vue-flatpickr-component";
  import "flatpickr/dist/flatpickr.css";
  import { required, max, is_earlier } from "@validations";
  import { ValidationProvider, ValidationObserver } from "vee-validate";
  import BCardCode from "@core/components/b-card-code/BCardCode.vue";
  
  import {
    BRow,
    BCol,
    BFormGroup,
    BInputGroupPrepend,
    BInputGroup,
    BDropdown,
    BDropdownItem,
    BDropdownItemButton,
  } from "bootstrap-vue";
  import Cleave from "vue-cleave-component";
  // eslint-disable-next-line import/no-extraneous-dependencies
  import "cleave.js/dist/addons/cleave-phone.us";
  
  import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
  
  import {
    BFormDatepicker,
    BFormCheckbox,
    BFormInput,
    BFormTextarea,
    BFormFile,
    BButton,
    BForm,
    BFormInvalidFeedback,
  } from "bootstrap-vue";
  export default {
  
  
    components: {
      flatPickr,
      ToastificationContent,
      required,
      BDropdown,
      BDropdownItem,
      BDropdownItemButton,
      BFormInvalidFeedback,
      VueGoodTable,
      BForm,
      ValidationObserver,
      ValidationProvider,
  
      BCardCode,
      BButton,
      BFormInput,
      BFormFile,
      BFormGroup,
      BInputGroupPrepend,
      BInputGroup,
      BFormDatepicker,
      Cleave,
      BFormTextarea,
      vSelect,
      BFormCheckbox,
      BRow,
  
      BCol,
    },
  
    data() {
      return {
        file: "",
        body:'',
        item:'',
    optionitem:[],
        item_name:'',
      
        date:'',
        notes:'',
        employe: "",
        id: "",
        searchTerm: "",
        vendor_id:'',
        purchase_order_id: "",
    optionVendor:[],
    optionPurchesOrder: [],
      };
    },
  
    created() {
   this.getPact()
  
   this.getVendor()

this.getPurchesOrder();
    },
   
  
    methods: {
        getPact() {
    
    this.$http.get(`/api/v1/purchase-contract/${this.$route.params.id}`).then((res) => {
      //console.log("purchase-contract",res.data);
      this.purchase_order_id = res.data.data.purchase_order_id.code;
      this.vendor_id=res.data.data.vendor_id.id;
      this.item_name=res.data.data.item_name;
      this.body=res.data.data.body;
      this.file=res.data.data.file;
      this.item=res.data.data.item_id;
    });
  },
  getPurchesOrder() {
      this.optionPurchesOrder=[]
      this.$http.get("/api/v1/purchase-order").then((res) => {
        //console.log(res);
        
        res.data.data.forEach((el) => {
          this.optionPurchesOrder.push({ value: el.id, label: `${el.code}  `
 });
        });
        //console.log(this.optionemploye);
      });
    },
    getVendor() {
      this.optionVendor=[]
      this.$http.get("/api/v1/vendor").then((res) => {
        //console.log('itemres',res);
        this.optionVendor=res.data.data;
       
        //console.log(this.optionVendor);
      });
    },
    
      update() {
        return new Promise((resolve, reject) => {
          this.$refs.simple
            .validate()
            .then((success) => {
              if (success) {
       
                const formData = new FormData();
        formData.append("user_id", this.employe);
        formData.append("date", this.date);
        formData.append("item_name", this.item_name);
        formData.append("body", this.body);
        formData.append("file",this.file);
        formData.append("item_id",this.item);
        formData.append("_method", "put");
       
        //console.log("formData",formData)
        this.$http.post(`/api/v1/pact/${this.$route.params.id}`, formData).then((res) => {
          //console.log(res);
          this.getPacts()
          this.$swal({
            title: "",
            text: "تمت تعديل العهدة بنجاح",
            icon: "success",
            confirmButtonText: "موافق",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
         
        });
      }})})
      },
      
  
      getPacts() {
        this.$http.get("/api/v1/pact").then((res) => {
          //console.log('pact', res.data.data)
          this.rows = res.data.data;
        });
      },
  
      //
    },
  };
  </script> 
  
  <style scoped>
  label {
    color: rgba(112, 192, 203, 255);
    font-weight: bold;
    font-family: "Cairo", sans-serif;
  }
  .btn-purple {
    color: white;
    background-color: #7e7a9f;
    margin-top: 25px;
  }
  
  span {
    font-weight: bold;
    font-family: "Cairo", sans-serif;
  }
  .align-left {
    left: 50px;
  }
  .but {
    top: 40px;
  }
  </style>